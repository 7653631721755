import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index/index.vue')
  },
  {
    path: '/honor',
    name: 'Honor',
    component: () => import('@/views/honor/index.vue')
  },
  {
    path: '/trends',
    name: 'Trends',
    component: () => import('@/views/trends/index.vue')
  },
  {
    path: '/trendsDetail',
    name: 'TrendsDetail',
    component: () => import('@/views/trends/detail.vue')
  },
  {
    path: '/softness',
    name: 'Softness',
    component: () => import('@/views/softness/index.vue')
  },
  {
    path: '/functions',
    name: 'Functions',
    component: () => import('@/views/functions/index.vue')
  },
  {
    path: '/us',
    name: 'Us',
    component: () => import('@/views/us/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
