<template>
  <div id="app">
    <router-link to="/"></router-link>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "app",
    mounted() {
      let device = navigator.userAgent.toLowerCase();
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(device)) {
        window.location.replace('https://m.hszhxsh.com');
      }
    }
  }
</script>

<style lang="scss">
@font-face {
  font-family: 'SourceHanSansCN-Regular';
  src: url('~@/assets/css/SourceHanSansCN-Regular.otf');
}

@font-face {
  font-family: 'YouSheBiaoTiHei';
  src: url('~@/assets/css/YouSheBiaoTiHei.ttf');
}

@font-face {
  font-family: "SourceHanSansCN-Medium";
  src: url("~@/assets/css/SourceHanSansCN-Medium.otf");
}
*{
  padding: 0;
  margin: 0;
  font-family: SourceHanSansCN-Regular;
}
html{
  background: #ffffff;
}
</style>
