let Rem = function() {
    // 表示1920的设计图,使用100PX的默认值
    let whdef = 100 / 1920;
    // 当前窗口的宽度
    let bodyWidth = document.body.clientWidth;
    // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    let rem = bodyWidth * whdef;
    document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
    console.log("执行了----")
};
function fontSize(res){
    const clientWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
    if (!clientWidth) return;
    let fontSize = clientWidth / 1920;
    return res*fontSize;
};


export default {
    Rem,
    fontSize
}
