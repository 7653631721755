import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Rem from "@/utils/rem";
import VueCoreVideoPlayer from 'vue-core-video-player'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
import wow from 'wowjs'
import "wowjs/css/libs/animate.css"
import VueLazyLoad from 'vue-lazyload'
Vue.prototype.$wow = wow

Vue.use(VueLazyLoad);
Vue.config.productionTip = false
window.addEventListener('load', Rem.Rem);
window.addEventListener('resize', Rem.Rem)
Rem.Rem();
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
